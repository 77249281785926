.ProseMirror {
	position: relative;
}

.ProseMirror {
	word-wrap: break-word;
	white-space: pre-wrap;
	white-space: break-spaces;
	-webkit-font-variant-ligatures: none;
	font-variant-ligatures: none;
	font-feature-settings: 'liga' 0; /* the above doesn't seem to work in Edge */
}

.ProseMirror pre {
	white-space: pre-wrap;
}

.ProseMirror li {
	position: relative;
}

.ProseMirror-hideselection *::selection {
	background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
	background: transparent;
}
.ProseMirror-hideselection {
	caret-color: transparent;
}

.ProseMirror-selectednode {
	outline: 2px solid #8cf;
}

/* Make sure li selections wrap around markers */

li.ProseMirror-selectednode {
	outline: none;
}

li.ProseMirror-selectednode:after {
	content: '';
	position: absolute;
	left: -32px;
	right: -2px;
	top: -2px;
	bottom: -2px;
	border: 2px solid #8cf;
	pointer-events: none;
}

.editor .title {
	width: 100%;
	background: white;
	font-weight: 400;
	font-size: 2em;
	color: #333;
	margin: 0;
	margin-bottom: 3px;
	border: 0;
}

.editor-container {
	background: white;
	overflow: visible;
}

.editor-button {
	margin-top: 30px;
	text-align: center;
}

.file.is-boxed .file-cta {
	padding: 1.8em 3.2em;
}

.image.blog-thumbnail {
	padding: 0 10px 0 0;
}

.image.blog-thumbnail img {
	width: 8.5rem;
	height: 5.1rem;
	border-radius: 3px;
	cursor: pointer;
}

.blog-thumbnail img.is-selected {
	border: 4px solid #e67e22;
}

.remove-button {
	position: absolute;
	z-index: 10;
	background-color: #fff;
	border-radius: 50%;
	left: calc(8.5em - 30px);
	top: 60%;
	color: #e67e22;
	cursor: pointer;
}

.pm-button {
	margin-right: 5px;
	background: 0 0;
	border: none;
	cursor: pointer;
	display: inline-block;
	height: 24px;
	padding: 3px 5px;
	width: 28px;
	box-sizing: border-box;
}

.pm-button:hover svg {
	filter: invert(47%) sepia(99%) saturate(1026%) hue-rotate(355deg) brightness(102%) contrast(80%);
}

.pm-button.active svg {
	filter: invert(47%) sepia(99%) saturate(1026%) hue-rotate(355deg) brightness(102%) contrast(80%);
}

.pm-button:disabled svg {
	filter: invert(75%);
}

.pm-floater {
	position: absolute;
	z-index: 10;
	border: 3px solid #151616;
	border-radius: 3px;
	white-space: nowrap;
	margin: 0;
	display: flex;
}

.pm-floater :last-child {
	margin: 0;
}

.pm-group {
	margin-right: 5px;
}

.pm-toolbar {
	border-bottom: 1px solid #eee;
	padding: 8px 12px;
	top: calc(4rem);
	position: -webkit-sticky;
	position: sticky;
	background: #fff;
	z-index: 1;
	box-sizing: border-box;
}

.ProseMirror {
	min-height: 300px;
	padding: 12px 20px;
	outline: none;
}

.ProseMirror-gapcursor {
	display: none;
	pointer-events: none;
	position: absolute;
}

.ProseMirror-gapcursor:after {
	content: '';
	display: block;
	position: absolute;
	top: -2px;
	width: 20px;
	border-top: 1px solid black;
	animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

.ProseMirror-focused .ProseMirror-gapcursor {
	display: block;
}

.tooltip-wrapper {
	display: inline-block;
	position: relative;
}

ul.comment-list {
	position: absolute;
	transform: translateX(-50%);
	width: 240px;
	margin: 0;
	margin-top: 12px;
	list-style-type: none;
	border: 1px solid #eee;
	border-radius: 8px;
	overflow: hidden;
	background-color: #fff;
	z-index: 999;
	font-size: 16px;
	animation: slide-up 100ms ease-in-out;
}

@keyframes slide-up {
	0% {
		margin-top: 36px;
	}
	100% {
		margin-top: 12px;
	}
}

ul.comment-list .comment-list-item {
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

ul.comment-list .comment-list-item:not(:last-of-type) {
	border-bottom: 1px solid #eee;
}

ul.comment-list .comment-delete {
	margin-left: 12px;
}

.comment {
	/* #e67e22 == rgba(230, 126, 34); */
	background-color: #e67e2277;
}

@keyframes ProseMirror-cursor-blink {
	to {
		visibility: hidden;
	}
}

.linter {
	background: #fbbfbb;
}

.linter.long-sentence {
	background: #ffe4b5;
}

.linter.passive-voice {
	background: #afcbff;
}

.linter.simpler-alternative {
	background: #cccccc;
}

.linter:hover::before,
.linter:hover::after {
	content: unset;
	opacity: 1;
	display: block;
	border: 1px solid #ff6961;
	background-color: white;
	z-index: 100;
	font-size: 16px;
	font-weight: bold;
	padding: 16px;
	border-radius: 4px;
	position: absolute;
	animation: slide-up 100ms ease-in-out;
}

.linter.long-sentence[class='linter long-sentence']:hover::after {
	content: unset;
}

.linter:hover::after {
	content: attr(msg);
	margin-top: 12px;
}

@media screen and (max-width: 768px) {
	.ProseMirror {
		padding: 12px 0;
	}

	.linter:hover::after {
		content: unset;
	}

	.linter:hover::before {
		content: attr(msg);
		transform: translateY(calc(-100% - 12px));
	}

	.linter.long-sentence[class='linter long-sentence']:hover::before {
		content: unset;
	}
}
