@import "./bulma/sass/utilities/initial-variables";
@import "./bulma/sass/utilities/functions";

// Update Link Color
$blue: #e67e22;

$grey-dark: #444;

// Add Site Theme
$carrot: #e67e22;
$carrot-light: #e67e2222;
$carrot-invert: #fff;

// Add a sans-serif family
$family-serif: Lato, 'Noto Sans', sans-serif;

// Use the new carrot as the primary color
$primary: $carrot;
$primary-light: $carrot-light;
$primary-invert: $carrot-invert;

// Use the existing orange as the danger color
$danger: $red;

// Use the new sans-serif family
$family-primary: $family-serif;
$navbar-height: 4rem;

$box-radius: $radius-small;
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$card-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$dimensions: 16 24 32 48 64 96 128;
