.is-material-progress {
	position: fixed;
	top: 0px;
	z-index: 999;
	height: 3px;
	display: block;
	width: 100%;
	background-color: #ffb16d;
	border-radius: 2px;
	background-clip: padding-box;
	overflow: hidden;
}
.is-material-progress .determinate {
	position: fixed;
	background-color: inherit;
	top: 0;
	bottom: 0;
	background-color: #e67e22;
	transition: width 0.3s linear;
}
.is-material-progress .indeterminate {
	background-color: #e67e22;
}
.is-material-progress .indeterminate:before {
	content: '';
	position: fixed;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: calc(100% - 3px);
	will-change: left, right;
	animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.is-material-progress .indeterminate:after {
	content: '';
	position: fixed;
	background-color: inherit;
	top: 0;
	left: 0;
	bottom: calc(100% - 3px);
	will-change: left, right;
	animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	animation-delay: 1.15s;
}
@-webkit-keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@-moz-keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	60% {
		left: 100%;
		right: -90%;
	}
	100% {
		left: 100%;
		right: -90%;
	}
}
@-webkit-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
@-moz-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	60% {
		left: 107%;
		right: -8%;
	}
	100% {
		left: 107%;
		right: -8%;
	}
}
