.has-cursor-pointer {
	cursor: pointer;
	&.has-text-primary:hover {
		color: $grey-dark !important;
	}
}

.is-transparent {
	opacity: 0;
}

::selection {
	color: #fff;
	background-color: #e99041;
	text-shadow: none;
}

.is-not-user-selectable * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* ------ Bulma Overrides ------ */

html,
body {
	background: #fff;
	color: #333;
	text-rendering: optimizeLegibility;
}

body {
	margin-top: 4rem;
}

.content {
	word-wrap: break-word;
}

.content p {
	margin: 0 !important;
}

.content h2,
.content h3 {
	font-size: 1.7rem;
	font-family: Lato, 'Noto Sans', sans-serif;
	font-weight: 900 !important;
	color: #555 !important;
}

.content.wrytUp h3 {
	font-size: 1.4rem;
}

.content.wrytUp p:first-of-type,
.content.wrytUp h2:first-child,
.content.wrytUp h3:first-child,
.content.wrytUp h2 + p,
.content.wrytUp h3 + p {
	padding-top: 0px;
}

.content.wrytUp p,
.content.wrytUp h2,
.content.wrytUp h3 {
	padding-top: 12px;
}

.content.wrytUp .image-blot {
	max-width: calc(100% - 2em);
	margin: 1.5rem auto;
}

.content.wrytUp .image-blot:nth-child(1) {
	margin-top: 0;
}

.content.wrytUp .image-blot:last-child {
	margin-bottom: 0;
}

.content iframe {
	margin: 2.5rem 0 2rem 0;
}

.content .adsbygoogle iframe {
	margin: unset;
	max-width: unset;
	max-height: unset;
}

.LazyLoad {
	opacity: 0;
	transition: all 500ms ease-in-out;
}

.LazyLoad.is-visible {
	opacity: 1;
}

.ql-editor {
	overflow-x: hidden;
}

.ql-toolbar.ql-snow {
	border-bottom: 1px solid #eee;
}

.box {
	box-shadow: none;
}

.ql-editor.ql-blank::before {
	font-style: normal;
}

.input,
textarea {
	color: #333;
	box-shadow: none;
}

.input.is-hovered,
.input:hover,
.textarea.is-hovered,
.textarea:hover {
	border-color: #e67e22;
}

input.title {
	box-shadow: none;
}

input:focus {
	outline: 0;
}

.content blockquote {
	display: block;
	background: none;
	border-width: 2px;
	border-style: solid;
	border-image: linear-gradient(
			to right,
			rgba(200, 200, 200, 0) 10%,
			rgba(200, 200, 200, 1) 50%,
			rgba(200, 200, 200, 0) 90%
		)
		1 1 100%;
	padding: 1.5em 1em 1.5em;
	margin: 2.5em 0 !important;
	position: relative;
	text-align: center;
}

.content blockquote:before {
	content: '\201C';
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, -75%);
	background: #fff;
	width: 3rem;
	height: 3rem;
	font: 4em Mukta, sans-serif;
	color: #666;
	text-align: center;
}

.content pre {
	border: 2px solid #eee;
	background: none;
	white-space: pre-wrap;
}

.content iframe {
	position: relative;
	width: 100vw;
	height: calc(100vw * 9 / 16);
	max-width: 640px;
	max-height: 360px;
}

/* ----- Card ----- */

.card {
	box-shadow: none;
}

.card .card-content {
	padding: 0.5rem 0;
}

.card .card-image .button {
	position: absolute;
	z-index: 10;
	border-radius: 50%;
	right: 10px;
	top: 10px;
	color: #e67e22;
}

.card .card-image .button.left {
	right: 50px !important;
}

/* ----- Navbar ----- */

.navbar-brand .navbar-item.logo {
	font-weight: 900;
	color: #e67e22;
	font-size: 1.25rem;
}

.navbar-brand .navbar-item:hover,
.navbar-brand .navbar-item:active {
	background-color: #fff;
}

.navbar-item form .field.has-addons .control .input:active {
	outline: none;
}

.navbar-item input::placeholder {
	color: #555;
}

.navbar-item-right {
	padding: 0 12px 0 0;
	margin-left: auto;
}

.navbar-item-right .navbar-burger.burger {
	color: #4a4a4a;
}

.navbar-item-right > .navbar-item {
	padding: 0;
}

.navbar-item-right > .navbar-item:only-child {
	padding: 0 1rem;
}

/* ------ OTHERS ------ */

.long-copy {
	line-height: 145%;
	width: 70%;
	margin-left: 15%;
}

.section.is-light {
	background-color: whitesmoke;
}

.modal {
	z-index: 40;
}

.input:focus,
.input.is-focused,
.input:active,
.input.is-active,
.textarea:focus,
.textarea.is-focused,
.textarea:active,
.textarea.is-active {
	box-shadow: 0 0 0 -0.125em rgba(230, 126, 34, 0.25);
}

.section {
	padding: 1rem 1px;
}

.content h1 {
	font-weight: 700;
}

.home .column {
	padding: 0.75rem 0.25rem;
}

ul.menu-list > li > a.is-active {
	border-color: #e67e22;
	border-left: 4px solid #e67e22;
	border-radius: 0;
	color: #e67e22;
	background-color: rgba(230, 126, 34, 0.06);
}

.ql-align-center {
	text-align: center;
}

.ql-align-justify {
	text-align: justify;
}

.ql-align-right {
	text-align: right;
}

/* ------ MODIFIERS ------ */

.is-circle {
	/* Always to be applied to Parent Container */
	border-radius: 50% !important;
	overflow: hidden;
}

.is-circle img {
	max-height: none !important;
	max-width: none !important;
	height: 100% !important;
	width: auto !important;
	min-width: 100% !important;
}

.is-fading-to-white {
	background: linear-gradient(transparent, white) !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
}

.is-flipped {
	transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
}

.is-inline {
	display: inline-block !important;
}

.is-top-marginless {
	margin-top: 0 !important;
}

.is-rounded-left {
	border-radius: 5px 0 0 5px !important;
}

.is-scroll-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
	top: calc(-100% + 240px) !important;
}

.is-borderless {
	border: 0 !important;
}

.has-padding-2em {
	padding: 2em !important;
}

.has-padding-1em {
	padding: 1em !important;
}

.has-padding-05em {
	padding: 0.5em !important;
}

.is-top-paddingless {
	padding-top: 0 !important;
}

.is-bottom-paddingless {
	padding-bottom: 0 !important;
}

.is-left-paddingless {
	padding-left: 0 !important;
}

.is-right-paddingless {
	padding-right: 0 !important;
}

.is-side-paddingless {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.is-top-down-paddingless {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.is-spaced-topdown {
	padding: 5px 0 !important;
}

.has-one-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.has-two-lines {
	margin: 0 0 0.25em 0;
	max-height: 2.4em !important;
	line-height: 1.2em !important;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	white-space: normal;
	-webkit-line-clamp: 2;
	overflow: hidden !important;
}

.has-opacity-80 {
	opacity: 0.8 !important;
}

.is-pointer {
	cursor: pointer !important;
}

.is-full-height {
	height: 100% !important;
}

.is-full-width {
	width: 100% !important;
}

.is-spaced-out {
	justify-content: space-between !important;
}

.is-44px-by-44px {
	height: 44px !important;
	width: 44px !important;
}

.is-80x80 {
	height: 80px !important;
	width: 80px !important;
}

.image.is-180x180 {
	width: 180px;
	height: 180px;
	border-radius: 10px;
}

.is-notification-dot {
	position: absolute !important;
	border-radius: 50%;
	margin-top: -8px;
	right: 4px;
	width: 16px;
	height: 16px;
	background-color: #e67e22;
	color: white;
	text-align: center !important;
	font-size: 0.75rem !important;
	line-height: normal !important;
}

.has-custom-scrollbar::-webkit-scrollbar {
	width: 8px;
	position: absolute;
}

.has-custom-scrollbar::-webkit-scrollbar-track {
	background: #fff;
}

.has-custom-scrollbar::-webkit-scrollbar-thumb {
	background: #aaa;
}

.has-custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #999;
}

.has-top-bottom-0 {
	top: 0 !important;
	bottom: 0 !important;
}

.has-bottom-0 {
	bottom: 0 !important;
}

.has-no-preset-height {
	height: auto !important;
	max-height: none !important;
}

.has-margin-top-down-1em {
	margin: 1em 0 !important;
}

.has-margin-bottom-1em {
	margin-bottom: 1em !important;
}

.has-margin-bottom-05em {
	margin-bottom: 0.5em !important;
}

.has-margin-bottom-01em {
	margin-bottom: 0.1em !important;
}

.has-margin-right-0 {
	margin-right: 0 !important;
}

.has-margin-right-12px {
	margin-right: 12px !important;
}

.has-margin-right-1rem {
	margin-right: 1rem !important;
}

.has-margin-side-auto {
	margin: 0 auto !important;
}

.has-width-40vw {
	width: 40vw !important;
}

.has-height-100vh {
	height: 100vh !important;
}

.has-text-weight-extrabold {
	font-weight: 900 !important;
}

.has-background-facebook {
	background-color: #1877f2 !important;
}

.has-background-google {
	background-color: #df4930 !important;
}

.has-background-twitter {
	background-color: #38a1f3 !important;
}

.has-background-whatsapp {
	background-color: #4fce5d !important;
}

.has-background-pinterest {
	background-color: #c8232c !important;
}

.has-background-light {
	background-color: #f8f8f8 !important;
}

.has-background-light-on-hover:hover {
	background-color: #f8f8f8 !important;
}

.has-padding-right {
	padding-right: 15px !important;
}

.has-display-inline-block {
	display: inline-block !important;
}

.has-opacity-0 {
	opacity: 0 !important;
}

.active-button {
	padding: 10px 0 !important;
	border-bottom: 2px solid #e67e22 !important;
}

.has-border-1px {
	border: 1px solid #eee !important;
}

.has-centered-vertical-horizontal {
	display: flex;
	align-items: center !important;
	justify-content: center !important;
}

.has-side-margin-16px {
	margin-left: 16px !important;
	margin-right: 16px !important;
}

.has-side-padding-5px {
	padding-right: 5px !important;
	padding-left: 5px !important;
}

.has-border-primary {
	border: 4px solid #e67e22 !important;
}

.has-bg-primary {
	background-color: #e67e22 !important;
}

.has-bgSize-cover {
	background-size: cover !important;
}

.has-overflowX-hidden {
	overflow-x: hidden !important;
}

.reset-line-height {
	line-height: normal !important;
}

.on-hover-has-text-grey-dark:hover {
	color: #444 !important;
}

.on-hover-has-text-primary:hover {
	color: #e67e22 !important;
}

img,
iframe {
	transition: all 0.5s ease-out;
}

img.lazy,
iframe.lazy {
	opacity: 0;
}

/* ------ Desktop Specific ------ */

@media screen and (min-width: 1024px) {
	.container.has-width-80-desktop {
		width: 80%;
	}
	.is-side-paddingless-desktop {
		padding-left: 0;
		padding-right: 0;
	}
	.is-left-paddingless-desktop {
		padding-left: 0;
	}
	.is-right-paddingless-desktop {
		padding-right: 0;
	}
}

/* ------ Mobile Specific ------ */

@media screen and (max-width: 768px) {
	.modal-content {
		position: absolute;
		bottom: 8px;
		left: 8px;
		right: 8px;
		width: unset;
		max-height: unset;
		margin: unset;
	}
	.navbar-item-right {
		padding: 0;
	}
	.section {
		padding: 1rem 0;
		padding-top: 0;
	}
	.content.wrytUp .image-blot {
		max-width: 100%;
	}
	.content p,
	.content h2,
	.content h3 {
		padding: 0 1.2rem;
	}
	.content pre {
		margin: 0 1.2rem;
	}
	.content li p {
		padding: 0 0 0 2px;
	}
	.content ul,
	.content ol {
		margin-right: 1.2rem;
	}
	.ql-editor {
		padding: 12px 0;
	}
	.ql-editor.ql-blank::before {
		margin-left: 1.2rem;
	}
	.is-left-paddingless-mobile {
		padding-left: 0 !important;
	}
	.is-side-paddingless-mobile {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.is-side-scrollable-mobile {
		flex-wrap: nowrap !important;
		max-width: 100vw;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.is-paddingless-mobile {
		padding: 0;
	}
	.home .column {
		padding: 0.75rem;
	}
	.home .columns.is-side-scrollable-mobile .column {
		padding-left: 0.75rem;
		padding-right: 0.25rem;
	}
	.has-height-100vh-mobile {
		height: 100vh;
	}
	.is-overflowX-scroll-mobile {
		overflow-x: scroll;
	}
	.column.is-side-paddingless-mobile .card > .card-content {
		padding: 8px 12px;
	}
}

/* ----- Tablet and Above Specific ----- */

@media screen and (min-width: 769px) {
	.columns.is-multiline-tablet {
		flex-wrap: wrap;
	}
	.field-label {
		flex-grow: 2;
	}
}

/* ----- Sharing Menu ----- */

.sm {
	position: fixed;
	top: calc(50vh + 40px);
	transform: translate(-80px, -50%);
}

.sm a {
	color: #333;
}

.sm a .fa-twitter:hover {
	color: #0084b4;
}

.sm a .fa-facebook:hover {
	color: #3b5998;
}

.sm a .fa-pinterest:hover {
	color: #c8232c;
}

/* ----- Inforbar ----- */

.infobar {
	position: absolute;
	width: 100%;
}

/* ----- Profile ----- */

.profile-img {
	width: 100%;
	max-width: 210px;
	max-height: 210px;
	object-fit: cover;
	border-radius: 10px;
}

#profile-socials {
	margin-left: -12px;
	margin-bottom: 12px;
}

.short-divider {
	width: 80px;
	background-color: #333;
	height: 3px;
	margin: 1.5rem 0;
}

/* ----- Login ----- */

.modal {
	margin-top: -64px;
}

@media screen and (min-width: 1088px) {
	.content .ql-editor .ql-video {
		position: relative;
		width: 656px;
		height: 369px;
		left: 50%;
		transform: translate(-50%, 0);
	}
	.navbar-dropdown {
		box-shadow: none;
		border: 2px solid #f5f5f5;
		border-radius: 6px;
		transform: translate(0, -8px);
	}
}

.page {
	position: absolute;
	top: 100px;
	left: 0;
	right: 0;
}

.waveLoader {
	margin: 0 auto;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
}

.waveLoader > div {
	margin: 0 1px;
	background-color: #e67e22;
	height: 100%;
	width: 6px;
	display: inline-block;
	border-radius: 16px;
	-webkit-animation: iL-StretchDelay 1.2s infinite ease-in-out;
	animation: iL-StretchDelay 1.2s infinite ease-in-out;
}

.waveLoader .rect2 {
	animation: iL-StretchDelay2 1.2s infinite ease-in-out;
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.waveLoader .rect3 {
	animation: iL-StretchDelay3 1.2s infinite ease-in-out;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.waveLoader .rect4 {
	animation: iL-StretchDelay2 1.2s infinite ease-in-out;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.waveLoader .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

/* ------ Animations ------ */

@keyframes iL-StretchDelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.15);
		transform: scaleY(0.15);
		border-radius: 50%;
	}
	20% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
		border-radius: 16px;
	}
}

@keyframes iL-StretchDelay2 {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.15);
		transform: scaleY(0.15);
		border-radius: 50%;
	}
	20% {
		-webkit-transform: scaleY(0.7);
		transform: scaleY(0.7);
		border-radius: 16px;
	}
}

@keyframes iL-StretchDelay3 {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.15);
		transform: scaleY(0.15);
		border-radius: 50%;
	}
	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		border-radius: 16px;
	}
}

/*  ------- Circular Loader ------- */

.sk-fading-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
}

.sk-fading-circle .sk-circle {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.sk-fading-circle .sk-circle:before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: #e67e22;
	border-radius: 100%;
	-webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
	animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
	-webkit-animation-delay: -0.7s;
	animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
	-webkit-animation-delay: -0.3s;
	animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

@keyframes sk-circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

/* Bug Fixes Related to Safari */

.is-not-user-selectable input {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

.content {
	font-family: 'Mukta', 'Noto Sans', sans-serif;
	font-size: 1.1em;
	font-weight: 400;
}
@media screen and (min-width: 1024px) {
	.content {
		font-size: 1.2em;
	}
}
.has-font-lato {
	font-family: 'Lato', 'Noto Sans', sans-serif !important;
}
.has-font-mukta {
	font-family: 'Mukta', 'Noto Sans', sans-serif !important;
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
	.column.is-offset-1-tablet-only {
		margin-left: 8.33333%;
	}
}

.ad-container {
	padding: 0.25rem 0.5rem 0.5rem 0.5rem;
	margin: 0.5rem 0;
	text-align: center;
	overflow: hidden;
	background-color: #f8f8f8;
	display: block;
}

.ad-container::before {
	content: 'ADVERTISEMENT';
	font-size: 10px;
	text-decoration: none;
	color: #555;
	display: block;
	position: relative;
}

ins.adsbygoogle {
	width: 100%;
	display: block;
}

.table.is-hoverable tbody tr:not(.is-selected):hover {
	cursor: pointer;
}
