@import './_theme';
@import './bulma/bulma';
@import './_utility';
@import './Editor.scss';

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Lato Regular'), local('Lato-Regular'),
		url('/fonts/Lato/lato-v14-latin-ext_latin-regular.woff2') format('woff2'),
		url('/fonts/Lato/lato-v14-latin-ext_latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Lato Bold'), local('Lato-Bold'), url('/fonts/Lato/lato-v14-latin-ext_latin-700.woff2') format('woff2'),
		url('/fonts/Lato/lato-v14-latin-ext_latin-700.woff') format('woff');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local('Lato Black'), local('Lato-Black'), url('/fonts/Lato/lato-v14-latin-ext_latin-900.woff2') format('woff2'),
		url('/fonts/Lato/lato-v14-latin-ext_latin-900.woff') format('woff');
}

.menu-list {
	a {
		padding: 1em 2em;
		&:hover {
			background: unset;
			color: $primary;
		}
		&.is-active {
			background-color: $primary-light;
			color: $primary;
		}
	}
}

.navbar {
	border-bottom: 1px solid #eeeeee;
}

.tag:not(body).is-primary.is-outline {
	color: $primary;
	background-color: unset;
	border: 1px solid $primary;
}

@media only screen and (min-width: 769px) {
	.navbar {
		.navbar-brand {
			padding-left: 24px;
		}
		.navbar-end {
			.navbar-item:last-child {
				padding-right: 24px;
			}
		}
	}
}
